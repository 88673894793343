import React, { useContext, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Route } from 'react-router-dom';
import Labels from '../custom-components/Labels';
import SmallLabels from '../custom-components/SmallLabels';
import LabelDetail from '../custom-components/LabelDetail';
import { isUserAuthenticated, getLoggedInUser } from '../helpers/authUtils';
import { UserContext } from '../contexts/UserContext';

// lazy load all the views
const EcommerceDashboard = React.lazy(() => import('../pages/dashboards/Ecommerce'));
const CRMDashboard = React.lazy(() => import('../pages/dashboards/CRM'));
const AnalyticsDashboard = React.lazy(() => import('../pages/dashboards/Analytics'));
// auth
const Login = React.lazy(() => import('../pages/auth/Login'));
const Logout = React.lazy(() => import('../pages/auth/Logout'));
const Register = React.lazy(() => import('../pages/auth/Register'));
const ForgetPassword = React.lazy(() => import('../pages/auth/ForgetPassword'));
const Confirm = React.lazy(() => import('../pages/auth/Confirm'));

// handle auth and authorization

const PrivateRoute = ({ component: Component, roles, ...rest }) => {

    const { isLoggedIn } = useContext(UserContext);

    const [loading, setLoading] = useState(true);

    const [loggedIn, setLoggedIn] = useState(false);

    // useEffect(() => {
    //     isLoggedIn()
    //     .then
    // },[])

    useEffect(() => {
        console.log('CHECKING LOGIN')
        setLoading(true)
        isLoggedIn()
        .then(res => setLoggedIn(true))
        .catch(err => setLoggedIn(false))
        .finally(res => setLoading(false))
    }, [])
    

    if (!loading){
        return (
            <Route
                {...rest}
                render={props => {
                    if (!loggedIn) {
                        // not logged in so redirect to login page with the return url
                        return <Redirect to={{ pathname: '/account/login', state: { from: props.location } }} />;
                    }
        
                    // authorised so return component
                    return <Component {...props} />;
                }}
            />
        )
    }

    return (
        <h1>Loading</h1>
    )

};

// root routes
const rootRoute = {
    path: '/',
    exact: true,
    component: () => <Redirect to="/labels" />,
    route: PrivateRoute,
};

// dashboards
// const dashboardRoutes = {
//     path: '/dashboard',
//     name: 'Dashboards',
//     icon: 'uil-home-alt',
//     header: 'Navigation',
//     children: [
//         {
//             path: '/dashboard/analytics',
//             name: 'Analytics',
//             component: AnalyticsDashboard,
//             route: PrivateRoute,
//         },
//         {
//             path: '/dashboard/crm',
//             name: 'CRM',
//             component: CRMDashboard,
//             route: PrivateRoute,
//         },
//         {
//             path: '/dashboard/ecommerce',
//             name: 'Ecommerce',
//             badge: {
//                 variant: 'success',
//                 text: '3',
//             },
//             component: EcommerceDashboard,
//             route: PrivateRoute,
//         },
//     ],
// };

const labelsRoute = {
    path: '/labels',
    name: 'Labels',
    route: PrivateRoute,
    icon: 'uil-receipt',
    component: Labels,
    header: 'Labels',
};

// const smallLabelsRoute = {
//     path: '/smallLabels',
//     name: 'Small Labels',
//     route: PrivateRoute,
//     icon: 'uil-receipt-alt',
//     component: SmallLabels,
//     header: 'Small Labels',
// };

// const labelDetailRoute = {
//     path: '/labelDetail/:workOrderNumber',
//     name: 'Label Detail',
//     route: PrivateRoute,
//     icon: 'uil-calender',
//     component: LabelDetail
// };

// const chatAppRoutes = {
//     path: '/apps/chat',
//     name: 'Chat',
//     route: PrivateRoute,
//     roles: ['Admin'],
//     icon: 'uil-comments-alt',
//     component: ChatApp,
// };

// const ecommerceAppRoutes = {
//     path: '/apps/ecommerce',
//     name: 'eCommerce',
//     route: PrivateRoute,
//     roles: ['Admin'],
//     icon: 'uil-store',
//     children: [
//         {
//             path: '/apps/ecommerce/products',
//             name: 'Products',
//             component: EcommerceProducts,
//             route: PrivateRoute,
//         },
//         {
//             path: '/apps/ecommerce/details',
//             name: 'Product Details',
//             component: ProductDetails,
//             route: PrivateRoute,
//         },
//         {
//             path: '/apps/ecommerce/orders',
//             name: 'Orders',
//             component: Orders,
//             route: PrivateRoute,
//         },
//         {
//             path: '/apps/ecommerce/order/details',
//             name: 'Order Details',
//             component: OrderDetails,
//             route: PrivateRoute,
//         },
//         {
//             path: '/apps/ecommerce/customers',
//             name: 'Customers',
//             component: Customers,
//             route: PrivateRoute,
//         },
//         {
//             path: '/apps/ecommerce/shopping-cart',
//             name: 'Shopping Cart',
//             component: Cart,
//             route: PrivateRoute,
//         },
//         {
//             path: '/apps/ecommerce/checkout',
//             name: 'Checkout',
//             component: Checkout,
//             route: PrivateRoute,
//         },
//         {
//             path: '/apps/ecommerce/sellers',
//             name: 'Sellers',
//             component: Sellers,
//             route: PrivateRoute,
//         },
//     ],
// };

const appRoutes = [
    labelsRoute,
    // smallLabelsRoute
    // labelDetailRoute
];

// auth
const authRoutes = {
    path: '/account',
    name: 'Auth',
    children: [
        {
            path: '/account/login',
            name: 'Login',
            component: Login,
            route: Route,
        },
        {
            path: '/account/logout',
            name: 'Logout',
            component: Logout,
            route: Route,
        },
        {
            path: '/account/register',
            name: 'Register',
            component: Register,
            route: Route,
        },
        {
            path: '/account/confirm',
            name: 'Confirm',
            component: Confirm,
            route: Route,
        },
        {
            path: '/account/forget-password',
            name: 'Forget Password',
            component: ForgetPassword,
            route: Route,
        },
    ],
};
const detailRoutes = {
    path: '/detail',
    name: 'Detail',
    children: [
        {
            path: '/labelDetail/:workOrderNumber',
            name: 'Label Detail',
            route: PrivateRoute,
            component: LabelDetail,
            exact: true
        }
    ],
};

// flatten the list of all nested routes
const flattenRoutes = routes => {
    let flatRoutes = [];

    routes = routes || [];
    routes.forEach(item => {
        flatRoutes.push(item);

        if (typeof item.children !== 'undefined') {
            flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
        }
    });
    return flatRoutes;
};

// All routes
const allRoutes = [rootRoute, ...appRoutes, authRoutes, detailRoutes];

const authProtectedRoutes = [...appRoutes];

const allFlattenRoutes = flattenRoutes(allRoutes);

export { allRoutes, authProtectedRoutes, allFlattenRoutes };
