import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { useHistory, useLocation } from "react-router-dom";
import { toast } from 'react-toastify';
import { BleamDoorsApi } from '../api/BleamDoorsApi';
// import { useToasts } from 'react-toast-notifications';
// import OmniApi from '../customAPI/OmniApi'

export const UserContext = createContext();

export const UserProvider = (props) => {

    const [userLoading, setUserLoading] = useState(false);

    const [loggedIn, setLoggedIn] = useState(false);

    const [user, setUser] = useState(null)

    let history = useHistory();
    let location  = useLocation();

    // const { addToast } = useToasts();

    // useEffect(() => {
    //     setUserLoading(true);
    //     axios.get(process.env.REACT_APP_API_BASE_URL + '/api/auth/profile')
    //     .then(r=> {
    //         setLoggedIn(true)
    //         setUserLoading(false)
    //     })
    //     .catch(e=> {
    //         setLoggedIn(false)
    //         setUserLoading(false)
    //     })
    //     .finally(() => setUserLoading(false))
    // }, [])

    const isLoggedIn = () => {

        console.log('checking now')

        // return true

        return new Promise(async (resolve, reject) => {

            try{

                console.log('cheksing')
        
                let { data } = await BleamDoorsApi.get('/api/auth/profile')

                setLoggedIn(false)
                setUser(data)
    
                resolve(data)
                
            } catch(err){
                setLoggedIn(true)
                setUser(null)
                reject(new Error('Invalid user'))
            }
    
        })

        // let returnedValue = await BleamDoorsApi.get('/api/auth/profile')

        // if(returnedValue.status === 401) {
        //     setLoggedIn(false)
        //     setUser(returnedValue.data)
        //     return false}

        // if(returnedValue.status === 200) {
        //     setLoggedIn(true)
        //     setUser(null)
        //     return true}

    }

    const loginUser = (loginInfo) => {
        setUserLoading(true);
        let userLoginToast = toast.loading('Logging in...', {
            theme: 'light',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
        BleamDoorsApi.post('/api/auth/login', loginInfo)
        .then(res => {
            // setLoggedIn(true)
            toast.update(userLoginToast, 
                { 
                    render: "Logged In",
                    type: "success", 
                    isLoading: false,
                    autoClose: null,
                    hideProgressBar: null,
                    closeOnClick: null,
                });
            history.push('/');
        })
        .catch(err => {
            // setLoggedIn(false)
            toast.update(userLoginToast, 
                { 
                    render: "Error " + err.response.status + ": " + err.response.statusText,
                    type: "error", 
                    isLoading: false,
                    autoClose: null,
                    hideProgressBar: null,
                    closeOnClick: null,
                });
        })
        .finally(() => setUserLoading(false))
    }

    const logoutUser = () => {
        let userLogoutToast = toast.loading('Logging out...', {
            theme: 'light',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
        BleamDoorsApi.delete('/api/auth/logout')
        .then(res => {
            setUser(null)
            // setLoggedIn(false)
            setLoggedIn(false)
            toast.update(userLogoutToast, 
                { 
                    render: "Logged Out",
                    type: "success", 
                    isLoading: false,
                    autoClose: null,
                    hideProgressBar: null,
                    closeOnClick: null,
                })

            history.push('/account/login');
        })
        .catch(err => {
            toast.update(userLogoutToast, 
                { 
                    render: "Error " + err.response.status + ": " + err.response.statusText,
                    type: "error", 
                    isLoading: false,
                    autoClose: null,
                    hideProgressBar: null,
                    closeOnClick: null,
                });

                history.push('/account/login');
        })
    } 
    
    return (
        <UserContext.Provider value={{ userLoading, loggedIn, loginUser, logoutUser, isLoggedIn, user }}>
            {props.children}
        </UserContext.Provider>
    );
}

