import React, { useState, useRef, useEffect } from 'react';
import { FormGroup, Row, Col, Label, Input, Button, Card, CardBody, Badge, ListGroup, ListGroupItem } from 'reactstrap';
import { workOrders } from '../data/labels';
import SimpleBar from 'simplebar-react';
import classnames from 'classnames';
import { useReactToPrint } from 'react-to-print';
import Barcode from 'react-barcode';
import PageTitle from '../components/PageTitle';
import './Label.css';
import axios from 'axios';
import bleamLogo from '../assets/logos/moldings_logo_3-05.png';
import { toast } from 'react-toastify';
import { confirm } from '../utility-components/ConfirmModal';
import { BleamDoorsApi } from '../api/BleamDoorsApi';

const LabelDetail = props => {

    const [workOrder, setWorkOrder] = useState('');

    const [activeLabel, setActiveLabel] = useState('');

    console.log(activeLabel)

    const onLabelClick = (e, id) => {
        e.preventDefault()
        setActiveLabel(workOrder.labels.find(label => label._id === id))
    }

    useEffect(() => {
        let workOrderNumber = props.match.params.workOrderNumber;

        BleamDoorsApi.get('/api/workOrders/' + workOrderNumber)
        .then(res => {
            setWorkOrder(res.data)
            setActiveLabel(res.data.labels[0])
        })
        .catch(err => console.log(err))
    }, [])

    const labelsRef = useRef();

    const [labelsForPrint, setLabelsForPrint] = useState('')

    // const prepareForPrint = () => {
    //     let labels = [];

    //     filteredInvoices.forEach(label => {
    //         if (label.tempLabelQty > 0) {
    //             for (let i = 0; i < label.tempLabelQty; i++) {
    //                 labels.push(label)
    //             }
    //         }
    //     })

    //     setLabelsForPrint(labels)

    //     console.log(labels, labels.length)
    // }

    // useEffect(() => {
    //     if (labelsForPrint)
    //     handlePrint()
    // }, [labelsForPrint])

    // const handlePrint = useReactToPrint({
    //     content: () => labelsRef.current,
    //     onAfterPrint: () => {

    //         setLabelsForPrint('')
    //     }
    // })

    const printLabel = (index) => {
        BleamDoorsApi
            .get('/api/workOrders/print', {
                params: {
                    wo: workOrder.workOrderNumber,
                    label: index,
                },
            })
            .then((res) => {
                toast.success('Label successfully sent to printer');
            })
            .catch((err) => {
                toast.error('Error');
            });

    }

    const printAllLabels = async () => {

        let cancelled = false;

        if(workOrder.printed){
            if (await confirm("These labels have already been printed. Do you want to proceed?", "Already Printed...", "Print Again") === false)
                cancelled = true
        }

        if(cancelled) return

        BleamDoorsApi
            .get('/api/workOrders/print', {
                params: {
                    wo: workOrder.workOrderNumber,
                    label: 'all',
                },
            })
            .then((res) => {
                toast.success('Labels successfully sent to printer');
            })
            .catch((err) => {
                toast.error('Error');
            });
    }

    return (
        <>
            <Row className="d-flex justify-content-center">
                <Col style={{ maxWidth: '75vw' }}>
                    <PageTitle
                        breadCrumbItems={[
                            { label: 'Labels', path: '/labels' },
                            {
                                label: 'Work Order #' + workOrder?.workOrderNumber,
                                path: '/labels/:labeldetail',
                                active: true,
                            },
                        ]}
                        title={'Work Order #' + workOrder?.workOrderNumber}
                    />
                </Col>
            </Row>
            <Row className="d-flex justify-content-center align-items-center">
                {/* <h2 className='position-absolute' style={{ left: '5px', top: '15px' }}>Work Order # HSAGGD4422</h2> */}
                <Card style={{ width: '75vw', height: '75vh' }}>
                    <Row className="h-100">
                        <Col className="m-0 p-0 h-100" style={{ minWidth: '200px', maxWidth: '200px' }}>
                            <SimpleBar className="h-100">
                                <ListGroup className="h-100">
                                    {workOrder &&
                                        workOrder.labels.map((label) => (
                                            <ListGroupItem
                                                tag="a"
                                                href="#"
                                                action
                                                className={classnames('', { active: activeLabel?._id === label._id })}
                                                onClick={(e) => {
                                                    console.log(activeLabel);
                                                    console.log(label);
                                                    onLabelClick(e, label._id);
                                                }}>
                                                <b>{label.doorNumber}</b>
                                                <br />
                                                {label.barcode}
                                            </ListGroupItem>
                                        ))}
                                </ListGroup>
                            </SimpleBar>
                        </Col>
                        <Col className="d-flex justify-content-center align-items-center position-relative">
                            {workOrder && <Button color='primary' size="lg" className='label-print-button-1 position-absolute'
                                onClick={printAllLabels}
                                disabled={!!workOrder.parseErrors.length}
                                >
                                    Print All
                                    <Badge className="position-absolute ml-1 d-flex justify-content-center align-items-center bg-danger
                                    print-all-badge"
                                    >
                                        {workOrder.labels.length}
                                    </Badge>    
                            </Button>}
                            <Button
                                color="light"
                                size="lg"
                                // style={{ height: '60px', width: '60px' }}
                                className="label-print-button-2 d-flex justify-content-center align-items-center"
                                onClick={() =>
                                    printLabel(workOrder.labels.findIndex((l) => l._id === activeLabel._id))
                                }>
                                {/* <i className="mdi mdi-printer" style={{ fontSize: '40px' }}></i> */}
                                Print Current
                            </Button>
                            <Row className="d-flex justify-content-center">
                                {activeLabel && (
                                    <Card style={{ width: '350px' }} className="border border-light shadow">
                                        <div className="d-flex justify-content-between align-items-center label-box-border m-2">
                                            <div className="d-flex justify-content-center align-items-center">
                                                <img src={bleamLogo} style={{ width: '85px', height: '85px' }} />
                                            </div>
                                            <div style={{ textAlign: 'end' }} className="mr-2">
                                                <div className="cls_004">
                                                    <span className="cls_004">1 Market Street</span>
                                                </div>
                                                <div className="cls_004">
                                                    <span className="cls_004">Passaic, NJ 07055</span>
                                                </div>
                                                <div className="cls_004">
                                                    <span className="cls_004">973.836.7100</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mx-2 d-flex flex-column justify-content-between">
                                            <div className="cls_008">WO: #{workOrder.workOrderNumber}</div>
                                            <div className="d-flex justify-content-between">
                                                <div className="cls_012">{workOrder.job}</div>
                                                <div className="cls_005 d-flex align-items-center">
                                                    {activeLabel.labelNumber}{' '}
                                                    of {workOrder.totalDoors}
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className='mt-1 d-flex flex-column justify-content-between'>
                                <div>
                                <div className="cls_003 text-right"><span className="cls_003 text-right">Date of confirmation</span></div>
                                <div className="cls_003 text-right"><span className="cls_003 text-right">{activeLabel.workOrderDate}</span></div>
                                </div>
                                <div>
                                <div className="cls_007 text-right"><span className="cls_007">5 of 49</span></div>
                                </div>
                                </div> */}
                                        <hr className="mx-2 mb-1 mt-0 label-hr" />
                                        {!activeLabel.hasOwnProperty('cut') ? (
                                            <>
                                                <div className="mx-2">
                                                    <div className="cls_006">
                                                        <span className="cls_006">{activeLabel.description}</span>
                                                    </div>
                                                    {/* <div className="cls_006"><span className="cls_006">and other accessories</span></div> */}
                                                    <div className="cls_006 mt-1">
                                                        <span className="cls_006">{activeLabel.hing}</span>
                                                    </div>
                                                </div>
                                                <div className="mx-2 mt-1 d-flex justify-content-center">
                                                    <div className="cls_002">
                                                        <span className="cls_002">{activeLabel.width} x&nbsp;</span>
                                                    </div>
                                                    <div className="cls_002">
                                                        <span className="cls_002">{activeLabel.height},&nbsp;</span>
                                                    </div>
                                                    <div className="cls_002">
                                                        <span className="cls_002">{activeLabel.thickness}</span>
                                                    </div>
                                                </div>
                                                <div className="mx-2 mt-1 d-flex justify-content-center">
                                                    <div className="cls_002">
                                                        <span className="cls_002">{activeLabel.direction}</span>
                                                    </div>
                                                </div>
                                                <div className="mx-2 mt-1 d-flex justify-content-center">
                                                    <div className="cls_002">
                                                        <span className="cls_002">{activeLabel.jamb}</span>
                                                    </div>
                                                </div>
                                            </>
                                        ) : activeLabel.hasOwnProperty('doorStop') ? (
                                            <div className="mx-2">
                                                <div className="cls_015">
                                                    <span className="cls_015" />
                                                    {activeLabel.doorStop}
                                                </div>
                                                {/* <div className="cls_006"><span className="cls_006">and other accessories</span></div> */}
                                                {/* <div className="cls_006 mt-1"><span className="cls_006">Hinge: {activeLabel.hing}</span></div> */}
                                            </div>
                                        ) : (
                                            <div className="mx-2">
                                                <div className="cls_015">
                                                    <span
                                                        className="cls_015"
                                                        dangerouslySetInnerHTML={{
                                                            __html: activeLabel.cut.replace(/\\&/g, '<br/>'),
                                                        }}
                                                    />
                                                </div>
                                                {/* <div className="cls_006"><span className="cls_006">and other accessories</span></div> */}
                                                {/* <div className="cls_006 mt-1"><span className="cls_006">Hinge: {activeLabel.hing}</span></div> */}
                                            </div>
                                        )}
                                        <hr className="mx-2 my-1 label-hr" />
                                        <div className="mx-2 mt-1 d-flex justify-content-center">
                                            <div className="cls_008">
                                                <span className="cls_008">{activeLabel.doorNumber}</span>
                                            </div>
                                        </div>
                                        <div className="mx-2 d-flex justify-content-center">
                                            <Barcode value={activeLabel.barcode} height="30" width="1" fontSize="10" />
                                        </div>
                                        <div className="mx-2 mt-2">
                                            <div className="cls_010">
                                                <span className="cls_010">Customer: {workOrder.customer}</span>
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-center mt-3 mb-2">
                                            <div>
                                                <div className="cls_011 text-center">
                                                    <span className="cls_011 text-center">
                                                        Door must be finished all 6
                                                    </span>
                                                </div>
                                                <div className="cls_011 text-center">
                                                    <span className="cls_011 text-center">sides immediately</span>
                                                </div>
                                            </div>
                                        </div>
                                    </Card>
                                )}
                            </Row>
                            <div className="d-none">
                                <LabelsToPrint labels={labelsForPrint} ref={labelsRef} />
                            </div>
                        </Col>
                    </Row>
                </Card>
            </Row>
            {/* <Row className='d-flex justify-content-center'>
            <Card>
        <div style={{position: 'relative', width:'288px', height:'576px', overflow:'hidden'}}>
        <div>
        <img src="background1.jpg" style={{ width: '288px', height: '576px' }} ></img></div>
        <div style={{position:'absolute',left:'163.29px',top:'28.18px'}} className="cls_004"><span className="cls_004">1 Market Street</span></div>
        <div style={{position:'absolute',left:'147.61px',top:'45.24px'}} className="cls_004"><span className="cls_004">Passaic, NJ 07055</span></div>
        <div style={{position:'absolute',left:'174.23px',top:'62.29px'}} className="cls_004"><span className="cls_004">973.836.7100</span></div>
        <div style={{position:"absolute", left:"214.45px", top:"106.82px"}} className="cls_003"><span className="cls_003">Date of confirmation</span></div>
        <div style={{position:'absolute',left:"16.82px",top:"108.24px"}} className="cls_002"><span className="cls_002">WO: #42334</span></div>
        <div style={{position:"absolute",left:"243.70px",top:"116.53px"}} className="cls_003"><span className="cls_003">12/22/2022</span></div>
        <div style={{position:"absolute", left:"16.82px", top:"127.89px"}} className="cls_005"><span className="cls_005">395 E Kent</span></div>
        <div style={{position:"absolute",left:"237.79px",top:"136.42px"}} className="cls_007"><span className="cls_007">5 of 49</span></div>
        <div style={{position:"absolute",left:"16.82px",top:"163.66px"}} className="cls_006"><span className="cls_006">Big wooden door with bronze knob and black hinges</span></div>
        <div style={{position:"absolute",left:"16.82px",top:"175.97px"}} className="cls_006"><span className="cls_006">and other accessories</span></div>
        <div style={{position:"absolute",left:"16.82px",top:"194.21px"}} className="cls_006"><span className="cls_006">Hinge: 3 1/2 x 3 1/2, Satin Black, Radius</span></div>
        <div style={{position:"absolute",left:"80.05px",top:"210.55px"}} className="cls_002"><span className="cls_002">2'0" x</span></div>
        <div style={{position:"absolute",left:"128.59px",top:"210.55px"}} className="cls_002"><span className="cls_002">6'8",</span></div>
        <div style={{position:"absolute",left:"170.12px",top:"210.55px"}} className="cls_002"><span className="cls_002">1" 7/8</span></div>
        <div style={{position:"absolute",left:"95.68px",top:"236.13px"}} className="cls_002"><span className="cls_002">TWIN L Active</span></div>
        <div style={{position:"absolute",left:"102.32px",top:"261.71px"}} className="cls_002"><span className="cls_002">Jamb: 4 7/8"</span></div>
        <div style={{position:"absolute",left:"108.00px",top:"331.11px"}} className="cls_008"><span className="cls_008">102A</span></div>
        <div style={{position:"absolute",left:"109.90px",top:"416.13px"}} className="cls_009"><span className="cls_009">123456789012</span></div>
        <div style={{position:"absolute",left:"16.82px",top:"456.39px"}} className="cls_010"><span className="cls_010">Customer: Patella Johnson</span></div>
        <div style={{position:"absolute",left:"83.02px",top:"479.84px"}} className="cls_011"><span className="cls_011">Door must be finished all 6</span></div>
        <div style={{position:"absolute",left:"105.39px",top:"495.95px"}} className="cls_011"><span className="cls_011">sides immediately</span></div>
        </div>
        </Card>
        </Row> */}
        </>
    );
}

class LabelsToPrint extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                {this.props.labels && this.props.labels.map(label => (
                    // <div className='d-flex justify-content-center align-items-center' style={{ pageBreakAfter: 'always', width: '100%', height: '100%', fontSize: '100px', textAlign: 'center' }}>
                    //     # {label.DocNumber} <br />
                    //     {label.ShipAddr?.Line1}<br />
                    //     {label.ShipAddr?.City}, {label.ShipAddr?.CountrySubDivisionCode} {label.ShipAddr?.PostalCode} 
                    // </div>
                    <div className='px-4' 
                    style={{ pageBreakAfter: 'always', fontSize: '50px', color: 'black', fontFamily: 'sans-serif' }}>
                        <Row className='d-flex justify-content-between'>
                            <Col xs={'auto'}>
                                WO # <b>{label.workOrderNumber}</b>
                            </Col>
                            <Col xs={'auto'}>
                                Date: <b>{label.workOrderDate}</b>
                            </Col>
                        </Row>
                        <Row className='py-4'>
                            <Col>
                                <div className='py-2 d-flex justify-content-around' style={{borderWidth: '4px', borderStyle: 'solid', borderColor: 'black', borderRadius: '7px'}}>
                                    <p className='mb-0'>Floor: <b>33rd</b></p>
                                    <p className='mb-0'>Door <b>{label.doorNumber}</b></p>
                                </div>
                            </Col>
                        </Row>
                        <Row className='d-flex justify-content-between'>
                            <Col xs='auto'>
                            <p><b>Description:</b> {label.doorDescription} </p>
                            </Col>
                            <Col xs='auto'>
                            <b>{label.direction}</b>
                            </Col>
                        </Row>
                        <Row className='d-flex justify-content-between pb-3'>
                            <Col xs={'auto'}>
                                <p className='my-2'><b>H:</b> {label.height} </p>
                                <p className='my-2'><b>W:</b> {label.width} </p>
                                <p className='my-2'><b>L:</b> {label.length} </p>
                                {/* <p><b>Description:</b> {label.doorDescription} </p> */}
                                {/* <p className='my-2'><b>direction:</b> {label.direction} </p> */}
                                <p className='my-2'><b>Hinge:</b> {label.hinge}</p>
                                <p className='my-2'><b>Jamb:</b> placeholder</p>
                            </Col>
                            {/* <Col xs={'auto'} className='d-flex flex-column justify-content-between'>
                                <div></div>
                                <div className='py-2' style={{borderWidth: '4px', borderStyle: 'solid', borderColor: '#CACED1', borderRadius: '7px'}}>
                                <h4 className='text-center'>Door {label.doorNumber}</h4>
                                </div>
                                <p><b>hinge:</b> {label.hinge}</p>
                            </Col> */}
                        </Row>
                        <hr style={{ backgroundColor: 'black', height: '5px' }}  />
                        <Row className='pt-3'>
                            <Col>
                            <div className='my-0'><b>Customer:</b></div>
                            <div>{label.customer}</div>
                            </Col>
                            <Col>
                            <div className='my-0'><b>Job:</b></div>
                            <div>{label.job}</div>
                            </Col>
                        </Row>
                        <Row className='d-flex justify-content-center mt-5'>
                            <Barcode 
                            value={label.barcode}
                            height='200'
                            width="6"
                            fontSize="42px"
                            />
                        </Row>
                    </div>
                ))}
            </div>
        )
    }
}

export default LabelDetail;