import axios from 'axios';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { Spinner } from 'reactstrap';
import { BleamDoorsApi } from '../api/BleamDoorsApi';
import throttle from 'lodash.throttle'

const RefreshCountdown = () => {
    const [seconds, setSeconds] = useState(60);
    const [loadingLastRefresh, setLoadingRefresh] = useState(true);

    const countdownTimeoutRef = useRef(null);

    const getLastRefresh = () => {
        setLoadingRefresh(true)
        BleamDoorsApi.get('/api/workOrders/lastRefresh')
        .then(res => {
            if(res.data.error)
                return toast.error(res.data.error)
            let oldDate = new Date(res.data.lastRefresh)
            let nowDate = new Date()
            let difference = nowDate - oldDate
            difference /= 1000
            setSeconds(60 - Math.floor(difference))
            setLoadingRefresh(false)
        })
        .catch(err => {
            setSeconds(0)
            console.log(err.message)})
    }

    const throttledGetLastRefresh = useMemo(
        () => throttle(getLastRefresh, 10 * 1000, { leading: true })
    , [])

    useEffect(() => {
        getLastRefresh()
    }, [])

    console.log(seconds)

    useEffect(() => {
  
        countdownTimeoutRef.current =  setTimeout(() => {
            setSeconds(seconds -1);
        }, 1000)

        if(seconds <= 0){
            // clearTimeout(countdownTimeoutRef.current)
            throttledGetLastRefresh()
        }

        return () => clearTimeout(countdownTimeoutRef.current)
  
    }, [seconds])

    return(
        <div className='d-flex bg-light align-items-center bg-white' 
        // style={{ width: '70px', height: '70px', top: '100px', right: '80px', 
        // // border: '3px solid #727cf5'
        // }}
        style={{ height: '70px', width: '118px' }}
        >
            <div className='d-flex align-items-center'>
            {/* <div style={{fontSize: '7px' }}>
            <strong>Refresh in</strong>
            </div> */}
            <div>
                Next refresh: 
            </div>
            <div style={{fontSize: '17px' }} className="ml-1">
            <strong>{(loadingLastRefresh || seconds < 0) ? <Spinner size="sm" /> : seconds}</strong>
            </div>
            {/* <div style={{fontSize: '7px' }}>
            <strong>seconds</strong>
            </div> */}
            </div>
        </div>
    )

}

export default RefreshCountdown;