// @flow
import React, { Component } from 'react';
import Routes from './routes/Routes';
import { BrowserRouter, Switch } from 'react-router-dom';
import { UserProvider } from './contexts/UserContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RefreshCountdown from './utility-components/RefreshCountdown';
import AuthModal from './utility-components/AuthModal';

// setup fake backend
import { configureFakeBackend } from './helpers';

// Themes
import './assets/scss/Saas.scss';

// For Dark import Saas-Dark.scss
// import './assets/scss/Saas-Dark.scss';

// For Modern import Modern.scss
// import './assets/scss/Modern.scss';
// For modern dakr import Modern-Dark.scss
// import './assets/scss/Modern-Dark.scss';

// For Creative demo import Modern.scss
// import './assets/scss/Creative.scss';
// For Creative dark demo import Modern.scss
// import './assets/scss/Creative-Dark.scss';

// configure fake backend
configureFakeBackend();

type AppProps = {};

/**
 * Main app component
 */
class App extends Component<AppProps> {
    render() {
        return (
            <BrowserRouter>
                <UserProvider>
                    <Routes></Routes>
                    {/* {process.env.NODE_ENV === 'production' && <AuthModal />} */}
                    <ToastContainer
                        position="bottom-right"
                        theme="light"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        icon
                        draggable
                        pauseOnHover
                    />
                </UserProvider>
            </BrowserRouter>
        );
    }
}

export default App;
