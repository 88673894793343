import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FormGroup, Row, Col, Label, Input, Button, Card, CardBody, Badge, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import { workOrders } from '../data/labels';
import { toast } from 'react-toastify';
import { confirm } from '../utility-components/ConfirmModal';
import { BleamDoorsApi } from '../api/BleamDoorsApi';

const Labels = () => {

    let history = useHistory()

    const [workOrder, setWorkOrder] = useState('')

    const [workOrderNumber, setWorkOrderNumber] = useState('');

    const [isParseErrorModalOpen, setIsParseErrorModalOpen] = useState(false);

    useEffect(() => {
        if(workOrder){
            if(workOrder.parseErrors.length){
                setIsParseErrorModalOpen(true)
                toast.error(workOrder.parseErrors.length + ' parse errors found')
            }
        }
    }, [workOrder])

    const toggleParseErrorModal = () => {
        setIsParseErrorModalOpen(!isParseErrorModalOpen)
    }

    const getWorkOrder = () => {

        if(!workOrderNumber){
            return
        }
        
        BleamDoorsApi.get('/api/workOrders/' + workOrderNumber)
        .then(res => setWorkOrder(res.data))
        .catch(err => {
            setWorkOrder('')
            toast.error('Work Order #' + workOrderNumber + ' not found')
        })
    }

    const printAllLabels = async () => {

        let cancelled = false;

        if(workOrder.printed){
            if (await confirm("These labels have already been printed. Do you want to proceed?", "Already Printed...", "Print Again") === false)
                cancelled = true
        }

        if(cancelled) return

        BleamDoorsApi
            .get('/api/workOrders/print', {
                params: {
                    wo: workOrder.workOrderNumber,
                    label: 'all',
                },
            })
            .then((res) => {
                toast.success('Labels successfully sent to printer');
            })
            .catch((err) => {
                toast.error('Error');
            });
    }

    return(
        <>
            {/* {workOrders.map(order => (
                <div>
                    <div>{order.workOrderNumber}</div>
                    <div>{order.workOrderDate}</div>
                    <hr></hr>
                </div>
            ))} */}
            <Row className='d-flex justify-content-center full-body-height mt-5'>
                <Col lg={8}>
                <Card>
                    <CardBody>
                        <Row className='d-flex justify-content-center'>
                            <Col>
                                <FormGroup>
                                    <Label style={{ fontSize: '20px' }}>Work Order #</Label>
                                    <div className='d-flex align-items-center'>
                                    <Input style={{ height: '48px', fontSize: '20px' }}
                                    placeholder="Enter Work Order #"
                                    value={workOrderNumber}
                                    onChange={(e) => setWorkOrderNumber(e.target.value)}
                                    onKeyDown={(e) => {
                                            if (e.key === "Enter") {
                                                getWorkOrder()
                                            }
                                    }}
                                    ></Input>
                                    <Button color='primary' size="lg" className='ml-2'
                                    onClick={getWorkOrder}
                                    >Go</Button>
                                    </div>
                                    {workOrder?.parseErrors?.length ? <div className="invalid-text">Work Order contains <b>{workOrder?.parseErrors?.length}</b> parse errors</div> : ''}
                                </FormGroup>
                            </Col>
                        </Row>
                        {workOrder && <Row>
                            <Col>
                                <div style={{borderWidth: '2.5px', borderStyle: 'solid', borderColor: '#CACED1', borderRadius: '7px'}}>
                                    <div className='d-flex justify-content-between align-items-center mx-3 my-2'>
                                    <div className='px-1 d-flex flex-column align-items-center shadow-sm' style={{ flexGrow: '1', borderRadius: '7px', borderWidth: '2px', borderStyle: 'solid', borderColor: '#eef2f7' }}>
                                        <div className='py-1' style={{ pointerEvents: 'none' }}><strong>WO# {workOrder.workOrderNumber}</strong></div>
                                        <div className='py-1' style={{ pointerEvents: 'none' }}>WO Date: <strong>{new Date(workOrder.WOdateCreated).toLocaleString()}</strong></div>
                                        <div className='d-flex w-100 justify-content-between py-2' style={{ pointerEvents: 'none' }}>
                                            <div>Customer: <strong>{workOrder.customer}</strong></div>
                                            <div>Total of Labels: <strong>{workOrder.labels.length}</strong></div>
                                        </div>
                                    </div>
                                    <div style={{ width: '20px' }}>

                                    </div>
                                    <div className='d-flex h-100 flex-column'>
                                        <Button color="light" size="lg"
                                            onClick={() => history.push('/labeldetail/' + workOrder.workOrderNumber)}
                                            disabled={!!workOrder.parseErrors.length}
                                            >
                                            Preview
                                        </Button>
                                        <Button color='light' size="lg" className='position-relative mt-2'
                                        onClick={printAllLabels}
                                        disabled={!!workOrder.parseErrors.length}
                                        >
                                            Print All Labels
                                            <Badge className="position-absolute ml-1 d-flex justify-content-center align-items-center bg-danger
                                            print-all-badge"
                                            >
                                                {workOrder.labels.length}
                                            </Badge>    
                                        </Button>
                                    </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>}
                    </CardBody>
                </Card>
                </Col>
            </Row>
            <ParseErrorModal  workOrder={workOrder} isOpen={isParseErrorModalOpen} toggle={toggleParseErrorModal} refreshWorkOrder={getWorkOrder} />
        </>
    )
}

const ParseErrorModal = ({ workOrder, isOpen, toggle, refreshWorkOrder }) => {

    console.log(workOrder.parseErrors)

    const refreshErrors = () => {
        refreshWorkOrder()
        toggle()
    }

    return(
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle} className="bg-danger">
                Parse Errors
            </ModalHeader>
            <ModalBody>
                {workOrder?.parseErrors?.map((error, i)=> (
                    <Row>
                        <Col>
                            {error}
                            {(i + 1 !== workOrder?.parseErrors?.length) ? 
                            <hr />
                            :
                            ''
                            }
                        </Col>
                    </Row>
                ))}
            </ModalBody>
            <ModalFooter >
                <Button color="primary" onClick={refreshErrors}>
                    Refresh Work Order
                </Button>{' '}
            </ModalFooter>
        </Modal>
    )
}

export default Labels;